<template>
    <div class="draftContractPop">
        <div class="types">
            <div class="types-row">
                <el-radio :label="1" v-model="status">
                    确认中
                </el-radio>
                <el-radio :label="2" v-model="status">
                    已确认
                </el-radio>
            </div>
        </div>
        <div class="text-center">
            <el-button type="primary" @click="onSubmit">
                确定
            </el-button>
            <el-button @click="closeFn">
                取消
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            status: 0,
        };
    },
    watch: {},
    computed: {},
    methods: {
        onSubmit() {
            if (!this.status) {
                this.$message.warning('请选择更改状态！');
                return;
            }
            this.$axios
                .post('/interfaceApi/sale/contract_price/modification/state/' + this.extr.rowData.cpmid + '/' + this.status)
                .then(res => {
                    this.$message.success('修改成功');
                    window.hideToast();
                    window.updateTable();
                })
                .catch(error => {
                    this.$message.error(error);
                });

        },
        closeFn() {
            window.hideToast();
        },
    },
    created() {
        this.status = this.extr.rowData.modification_state;
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.draftContractPop
    .types
        padding .2rem
        text-align center
        .types-row
            margin .2rem 0
    .text-center
        text-align center
        position absolute
        bottom .1rem
        width 100%
</style>